import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MaintenancePage from '../views/MaintenancePage.vue'
import SuccessView from '../views/SuccessView.vue'
import CancelView from '../views/CancelView.vue'
import LoginView from '../views/LoginView.vue'
import LogoutView from '../views/LogoutView.vue'
import StatusView from '../views/StatusView.vue'
import ReceiptView from '../views/ReceiptView.vue'
import AboutView from '../views/AboutView.vue'
import AdminLoginView from '../views/AdminLoginView.vue'
import AdminDashboardView from '../views/AdminDashboardView.vue'
import SpecialRegistrationView from '../views/SpecialRegistrationView.vue'
import VerifyCodeView from '../views/VerifyCodeView.vue'

console.log('Router configuration loading - Maintenance mode is OFF');

// Add a timestamp to prevent caching
const timestamp = new Date().getTime();

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    alias: ['/index.html', '/index'],
    props: { timestamp }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: MaintenancePage,
    props: { timestamp }
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: CancelView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/status/:confirmation',
    name: 'status',
    component: StatusView
  },
  {
    path: '/receipt',
    name: 'receipt',
    component: ReceiptView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  // Special hidden registration route
  {
    path: '/vJRv0OWUxKg63kTdOcCUec6DSVXXp8',
    name: 'special-registration',
    component: SpecialRegistrationView
  },
  // Admin routes
  {
    path: '/admin/login',
    name: 'admin-login',
    component: AdminLoginView
  },
  {
    path: '/admin',
    name: 'admin-dashboard',
    component: AdminDashboardView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/registrations',
    name: 'verify-code',
    component: VerifyCodeView,
    props: { timestamp: new Date().getTime() }, // Force fresh load for this critical component
    meta: { nocache: true }
  },
  {
    path: '/admin/logout',
    name: 'admin-logout',
    beforeEnter: (to, from, next) => {
      localStorage.removeItem('admin_token');
      localStorage.removeItem('admin_user');
      next('/admin/login');
    }
  },
  // Catch-all route - redirect to home page
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

// Add a global navigation guard to log route changes and prevent caching
router.beforeEach((to, from, next) => {
  console.log(`Route navigation: ${from.path} → ${to.path}`);
  
  // Add timestamp to query params to prevent caching
  if (!to.query.t) {
    to.query.t = timestamp;
    next({ ...to, replace: true });
    return;
  }
  
  // Special handling for routes that should never be cached
  if (to.meta.nocache) {
    to.query.nocache = new Date().getTime();
    next({ ...to, replace: true });
    return;
  }
  
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    // Check for admin token
    const adminToken = localStorage.getItem('admin_token');
    if (!adminToken) {
      next({
        path: '/admin/login',
        query: { ...to.query, redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router 